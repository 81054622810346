import React, { Component } from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

class App extends Component {
  render() {
    return (
        <SwaggerUI url="/openapi.yaml" />
    );
  }
}

export default App;
